<script setup lang="ts">
import { ROUTES } from '@configs'
const { gtEvent } = useGoogleTag()
const config = useRuntimeConfig()

const { t } = useI18n()
const topLinks = computed(() => [
  {
    key: 'guide',
    label: t('mainTop.mainTopLink.guide'),
    linkAction: () => {
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: 'I LIKE LM 소개 이동',
        eventLabel: 'I LIKE LM 소개',
        eventSlot: '',
        eventI18nAddr: '',
        eventComponent: 'NovaLayoutTopLink',
      })
      useNavigations({ external: true, url: config.public.NOVAGUIDE_URL })
    },
  },
  {
    key: 'reward',
    label: t('mainTop.mainTopLink.reward'),
    linkAction: () => {
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '리워드 안내 이동',
        eventLabel: '리워드 안내',
        eventSlot: '',
        eventI18nAddr: '',
        eventComponent: 'NovaLayoutTopLink',
      })
      useNavigations({ external: true, url: config.public.REWARD_GUIDE_URL })
    },
  },
  {
    key: 'event',
    label: t('mainTop.mainTopLink.event'),
    linkAction: () => {
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '이벤트 이동',
        eventLabel: '이벤트',
        eventSlot: '',
        eventI18nAddr: '',
        eventComponent: 'NovaLayoutTopLink',
      })
      useNavigations({ url: ROUTES.BOARD_EVENT.path })
    },
  },
  {
    key: 'support',
    label: t('mainTop.mainTopLink.support'),
    linkAction: () => {
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '펀드 지원 이동',
        eventLabel: '펀드 지원',
        eventSlot: '',
        eventI18nAddr: '',
        eventComponent: 'NovaLayoutTopLink',
      })
      useNavigations({ url: ROUTES.FUND.path })
    },
  },
])
</script>

<template>
  <div class="top-link-wrap">
    <div class="inner">
      <button
        v-for="link in topLinks"
        :key="link.key"
        type="button"
        class="top-link"
        @click="link.linkAction"
      >
        <span :class="['label', `icon-${link.key}`]">{{ link.label }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-link-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;

  &:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: inline-block;
    width: 100%;
    height: 3px;
    content: '';
    background: #f0f0f0;
    transform: translateX(-50%);
  }

  .inner {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;

    @include mobile {
      column-gap: 2px;
    }
  }

  @include tablet {
  }

  @include mobile {
    padding: 25px 16px;
    gap: 5px;
  }

  .top-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 4 - 30px);
    height: 70px;
    padding: 0 10px;
    border-radius: 15px;
    box-shadow: 0 2px 4px 0 #00000014;
    background: #fff;

    @include tablet {
      width: calc(100% / 4 - 10px);
    }

    @include mobile {
      width: 25%;
      height: auto;
      padding: 0 2px;
      align-items: flex-start;
      line-height: 1.2em;
      background-color: transparent;
      box-shadow: none;
    }

    .label {
      display: inline-block;
      min-height: 36px;
      padding-left: 44px;
      @include text-style($text-custom-18-medium);
      line-height: 36px;
      @include tablet {
        @include text-style($text-heading3-medium);
        line-height: 36px;
      }
      @include mobile {
        font-size: 12px;
        padding-left: 0;
        padding-top: 38px;
        line-height: 1.2em;
      }

      &.icon-guide {
        background-image: url('@/assets/images/icon-horn.svg?url');
        background-repeat: no-repeat;
        background-size: 36px auto;
        background-position: left center;

        @include mobile {
          background-position: center top;
        }
      }

      &.icon-reward {
        background-image: url('@/assets/images/icon-wallet.svg?url');
        background-repeat: no-repeat;
        background-size: 36px auto;
        background-position: left center;

        @include mobile {
          background-position: center top;
        }
      }

      &.icon-event {
        background-image: url('@/assets/images/icon-giftbox.svg?url');
        background-repeat: no-repeat;
        background-size: 36px auto;
        background-position: left center;

        @include mobile {
          background-position: center top;
        }
      }

      &.icon-support {
        background-image: url('@/assets/images/icon-graph.svg?url');
        background-repeat: no-repeat;
        background-size: 36px auto;
        background-position: left center;

        @include mobile {
          background-position: center top;
        }
      }
    }
  }
}
</style>
